<template>
  <v-dialog v-model="datePicker" max-width="350px">
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar color="primary" dark fixed top>
          <v-icon @click="datePicker = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center">
            Datum wählen
          </v-toolbar-title></v-toolbar
        >
      </v-card-title>
      <v-card-text class="text-center mt-8">
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          first-day-of-week="1"
          color="primary"
        >
          <v-spacer></v-spacer>
          <!--Button um Datepicker zu schließen-->
          <v-btn text color="primary" @click="datePicker = false"
            >Abbrechen</v-btn
          >
          <!--Button welcher Datepicker schließt und das ausgewählte Datum speichert-->
          <v-btn
            text
            color="primary"
            @click=";(datePicker = false), datePicked()"
            >OK</v-btn
          >
        </v-date-picker>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    datePicker: false,
    date: ''
  }),
  methods: {
    open(date = '') {
      this.date = date
      console.warn(this.date)
      this.datePicker = true
    },
    datePicked() {
      this.$emit('datePicked', this.date)
    }
  }
}
</script>

<style></style>
