<template>
  <v-dialog v-model="taskAssignedGroup" scrollable max-width="900px" persistent>
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="taskAssignedGroup = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Benutzer in Gruppe</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <h3 class="mt-2 ml-2 mr-2 text-h6">
          Benutzer in Gruppe {{ group.name }}
        </h3>

        <v-data-table
          :items="group.users"
          :headers="userHeaders"
        ></v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="taskAssignedGroup = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
    <WarningDialog
      ref="warningDeleteUser"
      @warningActionPerformed="removeUser"
    />
  </v-dialog>
</template>

<script>
import WarningDialog from '../core/WarningDialog.vue'

export default {
  data: () => ({
    taskAssignedGroup: false,
    group: [],
    userHeaders: [
      { text: 'UserId', value: 'userId' },
      { text: 'Benutzername', value: 'username' },
      { text: 'E-Mail', value: 'email' }
    ]
  }),
  methods: {
    open(group) {
      this.group = group
      this.taskAssignedGroup = true
    }
  },
  components: {
    WarningDialog
  }
}
</script>

<style></style>
