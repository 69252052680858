<template>
  <v-dialog v-model="taskObjects" scrollable max-width="1600" persistent>
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="taskObjects = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center">Basis</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="2"
            ><h3 class="text-center mb-3 pa-2 mt-4">
              Auswahl
            </h3>
            <v-list>
              <v-list-item
                v-for="adr in selectedAddresses"
                :key="adr.lfdnr"
                dense
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ adr.name }}</v-list-item-title>
                  <span v-for="doc in selectedDocuments" :key="doc.nr">
                    <span v-if="doc.address.lfdnr === adr.lfdnr"
                      ><v-icon small class="mb1 pa-1 ">mdi-file-document</v-icon
                      >{{ doc.nr }}</span
                    >
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
            <v-text-field
              class="pa-2 mt-4"
              dense
              label="Textsuche (mind. 2 Zeichen)"
              outlined
              append-icon="mdi-magnify"
              clearable
              :rules="[twoCharactersValidation]"
              v-model="fullTextSearchInput"
              @input="searchTrigger"
            ></v-text-field>
            <v-data-table
              hide-default-header
              :items-per-page="5"
              :items="addressResult"
              :headers="headersAddresses"
              ><template v-slot:item.name="{ item }">
                <v-icon
                  class="mr-2"
                  v-if="selectedAddresses.includes(item)"
                  color="primary"
                  >mdi-account</v-icon
                >
                <v-icon class="mr-2" v-else>mdi-account</v-icon>
                <span>{{ item.name }}</span> </template
              ><template v-slot:item.actions="{ item }">
                <v-btn
                  fab
                  small
                  text
                  elevation="0"
                  @click="addAddress(item)"
                  v-if="!selectedAddresses.includes(item)"
                >
                  <v-icon small> mdi-plus </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  text
                  elevation="0"
                  @click="addAddress(item)"
                  v-else
                >
                  <v-icon small> mdi-minus </v-icon>
                </v-btn>
              </template></v-data-table
            >
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
            <v-text-field
              class="pa-2 mt-4"
              dense
              outlined
              label="Dokumente durchsuchen"
              v-model="documentSearch"
            ></v-text-field>
            <v-data-table
              hide-default-header
              :search="documentSearch"
              :items-per-page="5"
              :items="documents"
              :headers="headersDocuments"
              @click:row="addDocumentToShow"
              ><template v-slot:item.nr="{ item }">
                <v-icon
                  class="mr-2"
                  v-if="selectedDocuments.includes(item)"
                  color="primary"
                  >mdi-file-document</v-icon
                ><v-icon class="mr-2" v-else>mdi-file-document</v-icon>
                <span>{{ item.shortType }} {{ item.nr }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  fab
                  small
                  text
                  elevation="0"
                  @click="clickDocument(item)"
                  v-if="!selectedDocuments.includes(item)"
                >
                  <v-icon small> mdi-plus </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  text
                  elevation="0"
                  @click="clickDocument(item)"
                  v-else
                  ><v-icon small>mdi-minus</v-icon></v-btn
                >
              </template></v-data-table
            >
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
            <template v-if="documentToShow !== undefined">
              <h3 class="text-center mb-3 pa-2 mt-4">
                Enthaltene Artikel in {{ documentToShow.shortType }}
                {{ documentToShow.nr }}
              </h3>
              <v-data-table
                :items="documentToShow.positions"
                :headers="positionHeaders"
                :items-per-page="5"
                item-key="articleNr"
                class="mt-3"
                sort-by="articleNr"
              >
                <template v-slot:item.articleDescription="{ item }">
                  {{
                    item.articleDescription == null
                      ? '-'
                      : item.articleDescription
                  }}
                </template>

                <template v-slot:item.articleDescription2="{ item }">
                  {{
                    item.articleDescription2 == null
                      ? '-'
                      : item.articleDescription2
                  }}
                </template>

                <template v-slot:no-data>
                  Keine Artikel vorhanden
                </template>
              </v-data-table>
            </template>
            <h3 class="text-center mb-3 pa-2 mt-4" v-else>
              Wählen Sie ein Dokument aus
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="taskObjects = false">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    taskObjects: false,
    objects: [],
    fullTextSearchInput: '',
    addressResult: [],
    selectedAddresses: [],
    documents: [],
    headersAddresses: [
      { text: 'Name', value: 'name' },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    headersDocuments: [
      { text: 'Nr.', value: 'nr' },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    positionHeaders: [
      {
        text: 'Artikel-Nr.',
        value: 'articleNr'
      },
      {
        text: 'Artikel-Bezeichnung',
        value: 'articleDescription'
      },
      {
        text: 'Artikel-Bezeichnung 2',
        value: 'articleDescription2'
      }
    ],
    documentSearch: '',
    selectedDocuments: [],
    documentToShow: undefined
  }),
  methods: {
    open(objects = []) {
      this.taskObjects = true
      this.objects = objects
    },
    twoCharactersValidation(value) {
      return (
        this.filtersActive ||
        (!!value && String(value).length > 2) ||
        'Bitte geben Sie mehr als zwei Zeichen ein'
      )
    },
    searchTrigger() {
      if (
        this.fullTextSearchInput != null &&
        this.fullTextSearchInput != '' &&
        String(this.fullTextSearchInput).length > 2
      ) {
        axios
          .get('v1/crm/addresses/search/fullText', {
            params: { q: this.fullTextSearchInput }
          })
          .then(data => {
            this.addressResult = data.data
          })
      }
    },
    addAddress(adr) {
      if (!this.selectedAddresses.includes(adr)) {
        this.selectedAddresses.push(adr)
        axios.get(`v1/crm/addresses/${adr.lfdnr}/documents`).then(data => {
          data.data.forEach(element => {
            element.address = adr
            this.documents.push(element)
          })
        })
      } else {
        this.selectedAddresses.splice(this.selectedAddresses.indexOf(adr), 1)
        this.documents = this.documents.filter(doc => {
          return !(adr.lfdnr === doc.address.lfdnr)
        })
        this.selectedDocuments = this.selectedDocuments.filter(doc => {
          return !(adr.lfdnr === doc.address.lfdnr)
        })
      }
    },
    clickDocument(doc) {
      if (!this.selectedDocuments.includes(doc)) {
        this.selectedDocuments.push(doc)
      } else {
        this.selectedDocuments.splice(this.selectedDocuments.indexOf(doc), 1)
      }
    },
    addDocumentToShow(doc) {
      this.documentToShow = doc
    }
  }
}
</script>

<style></style>
