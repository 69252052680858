<template>
  <v-dialog v-model="taskAssigned" scrollable max-width="1000px" persistent>
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="taskAssigned = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center">Zuordnung</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <h3 class="mt-2 ml-2 mr-2 text-h6">Benutzer</h3>
        <v-autocomplete
          v-model="usersToAdd"
          label="Benutzersuche"
          class="mx-2"
          :items="assignableUsersFiltered"
          multiple
          chips
          deletable-chips
          item-text="username"
          :item-value="item => item"
        ></v-autocomplete>
        <v-btn
          class="mt-n3 mb-2 ml-2"
          color="primary"
          elevation="0"
          :disabled="usersToAdd.length === 0"
          @click="addUsers"
          ><v-icon class="mr-2">mdi-plus</v-icon>Hinzufügen</v-btn
        >
        <v-data-table :items="users" :headers="userHeaders"
          ><template v-slot:item.actions="{ item }">
            <v-btn
              fab
              small
              text
              elevation="0"
              color="red"
              class="col-xl-5"
              @click="tryRemoveUser(item)"
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template></v-data-table
        >
        <v-divider class="my-3 "></v-divider>
        <h3 class="mx-2 text-h6">Gruppen</h3>
        <v-autocomplete
          v-model="groupsToAdd"
          label="Gruppensuche"
          class="mx-2"
          :items="assignableGroupsFiltered"
          multiple
          chips
          deletable-chips
          item-text="name"
          :item-value="item => item"
        ></v-autocomplete>
        <v-btn
          class="mt-n3 mb-2 ml-2"
          color="primary"
          elevation="0"
          :disabled="groupsToAdd.length === 0"
          @click="addGroups"
          ><v-icon class="mr-2">mdi-plus</v-icon>Hinzufügen</v-btn
        >
        <v-data-table :headers="groupHeaders" :items="groups"
          ><template v-slot:item.actions="{ item }">
            <v-btn
              fab
              small
              text
              elevation="0"
              class="col-xl-5"
              @click="openTaskAssignedGroup(item)"
            >
              <v-icon small> mdi-eye </v-icon>
            </v-btn>
            <v-btn
              fab
              small
              text
              elevation="0"
              color="red"
              class="col-xl-5"
              @click="tryRemoveGroup"
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click=";(taskAssigned = false), save()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
    <TaskAssignedGroup ref="group" />
    <WarningDialog
      ref="warningDeleteUser"
      @warningActionPerformed="removeUser"
    />
    <WarningDialog
      ref="warnignDeleteGroup"
      @warningActionPerformed="removeGroup"
    />
  </v-dialog>
</template>

<script>
import TaskAssignedGroup from './TaskAssignedGroup.vue'
import { mapState } from 'vuex'
import WarningDialog from '../core/WarningDialog.vue'

export default {
  data: () => ({
    taskAssigned: false,
    objects: [],
    users: [],
    groups: [],
    userHeaders: [
      { text: 'UserId', value: 'userId' },
      { text: 'Benutzername', value: 'username' },
      { text: 'E-Mail', value: 'email' },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    groupHeaders: [
      { text: 'Code', value: 'code' },
      {
        text: 'Gruppenname',
        value: 'name'
      },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    usersToAdd: '',
    userToRemove: '',
    groupsToAdd: '',
    groupToRemove: '',
    assignableUsersFiltered: [],
    assignableGroupsFiltered: []
  }),
  methods: {
    open(users = [], groups = []) {
      this.taskAssigned = true
      this.users = users
      this.groups = groups
      this.filterAssignableUsers()
      this.filterAssignableGroups()
    },
    openTaskAssignedGroup(item) {
      this.$refs.group.open(item)
    },
    filterAssignableUsers() {
      this.assignableUsersFiltered = this.assignableUsers.filter(user => {
        return !this.users.some(someUser => someUser.userId === user.userId)
      })
    },
    filterAssignableGroups() {
      this.assignableGroupsFiltered = this.assignableWorkGroups.filter(
        group => {
          return !this.groups.some(someGroup => someGroup.code === group.code)
        }
      )
    },
    addUsers() {
      this.usersToAdd.forEach(element => {
        this.users.push(element)
      })
      this.usersToAdd = []
      this.filterAssignableUsers()
      //TODO: AXIOS CALL
    },
    addGroups() {
      this.groupsToAdd.forEach(element => {
        this.groups.push(element)
      })
      this.groupsToAdd = []
      this.filterAssignableGroups()
      //TODO: AXIOS CALL
    },
    tryRemoveUser(user) {
      var actions = [
        {
          icon: 'mdi-delete',
          text: 'Löschen',
          action: 'delete'
        },
        { icon: 'mdi-close', text: 'Abbrechen', action: 'cancel' }
      ]

      this.$refs.warningDeleteUser.open(
        'Wollen Sie den Benutzer aus der Zuordnung entfernen?',
        actions
      )

      this.userToRemove = user
    },
    removeUser(action) {
      if (action === 'delete') {
        this.users.splice(
          this.users.findIndex(u => u.userId === this.userToRemove.userId),
          1
        )
        this.filterAssignableUsers()
        //TODO: AXIOS CALL
      }
    },
    tryRemoveGroup(group) {
      var actions = [
        {
          icon: 'mdi-delete',
          text: 'Löschen',
          action: 'delete'
        },
        { icon: 'mdi-close', text: 'Abbrechen', action: 'cancel' }
      ]

      this.$refs.warnignDeleteGroup.open(
        'Wollen Sie die Gruppe aus der Zuordnung entfernen?',
        actions
      )

      this.groupToRemove = group
    },
    removeGroup(action) {
      if (action === 'delete') {
        this.groups.splice(
          this.groups.findIndex(g => g.code === this.groupToRemove.code),
          1
        )
        this.filterAssignableGroups()
        //TODO: Axios CALL
      }
    },
    save() {
      this.$emit('save', this.users, this.groups)
    }
  },
  mounted() {
    this.$store.dispatch('loadAssignableUsers')
    this.$store.dispatch('loadAssignableWorkGroups')
  },
  computed: {
    ...mapState({
      assignableUsers: state => state.task.assignableUsers,
      assignableWorkGroups: state => state.task.assignableWorkGroups
    })
  },
  components: {
    TaskAssignedGroup,
    WarningDialog
  }
}
</script>

<style></style>
